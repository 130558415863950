import React from "react";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import Home from '../../images/logo.png'
// import Banner from '../../images/HomeBanner/banner1.jpg'
// import { Container } from 'reactstrap';
import Exp1 from '../../images/ExploreProducts/exp1.jpg'
import Exp2 from '../../images/ExploreProducts/exp2.jpg'
import Exp3 from '../../images/ExploreProducts/exp3.jpg'
import Exp4 from '../../images/ExploreProducts/exp4.jpg'
import Exp5 from '../../images/ExploreProducts/exp5.jpg'


export default function SimpleSlider() {
    var settings = {

        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,

    };
    return (
        <Slider {...settings}>
            <div className="explore">
                <img src={Exp1} alt="img" />
            </div>
            <div className="explore">
                <img src={Exp2} alt="img" />
            </div>
            <div className="explore">
                <img src={Exp3} alt="img" />
            </div>
            <div className="explore">
                <img src={Exp4} alt="img" />
            </div>
            <div className="explore">
                <img src={Exp5} alt="img" />
            </div>
        </Slider>
    );
}