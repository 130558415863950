import React from "react";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useFirebaseApp } from 'reactfire';

// import Home from '../../images/logo.png'
// import Banner from '../../images/HomeBanner/banner1.jpg'
// import {Container } from 'reactstrap';


export default function SimpleSlider() {
  const firebase = useFirebaseApp();
  console.log(firebase);

  var settings = {

    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  return (
    <Slider {...settings}>
      <div>
        <div className="res_banner">
          <div className="banner1"></div>
        </div>
      </div>
      <div className="res_banner">
        <div className="banner2"></div>
      </div>
      <div className="res_banner">
        <div className="banner3"></div>
      </div>
      <div className="res_banner">
        <div className="banner4"></div>
      </div>
      <div className="res_banner">
        <div className="banner5"></div>
      </div>
    </Slider>
  );
}